import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="How It Works" />

    <div className={"page-header"}>
      {/* <img src={SlackDataPolicyTrustImage}/> */}

      {/* <img data-src="holder.js/500x100?theme=gray" /> */}
      <h2>How TheLeanFounder Works?</h2>
      {/* <p>TheLeanFounder is excited to help partner organizations everywhere<br />
            we can help your platform or enterprise be efficient with digital protection to be more trusted with customers.</p> */}
    </div>
    <div className="container marketing">
      <div className={"row"}>
        <div className="col-md-12">
        {/* <h4>Overview</h4> */}

        <br />
        <br />
        <h4>Step 1</h4>

        <br />
        <h5>Scanning the internet and monitoring the landscape</h5>
        <br />
        <p>
          A new app, site, service gets launched or organization added to our
          index Company admin needs to enable the integration one time for the
          whole company to use.
        </p>
        <p>
          You'll see all the data privacy and data policy information about each
          service you care about that's potentially exposing your data and
          shared or sold across the web. We even give you the ability to keep up
          to date with a single click.
        </p>

        {/* <img alt={"News"} src={thumbnailGuid1}/> */}
        {/* <img data-src="holder.js/754x354?theme=gray" /> */}

        <h4>Step 2</h4>

        <br />
        <h5>Assessing personal data risk</h5>
        <br />
        <p>
          Our systems analyze the applications, related data, policies and user
          agreements for common questions, risks and threats and generates
          scorecards and an updated report and notify the original organization
          to disclose more information.
        </p>

        <p>
          Define what matters to you out of the parameters we are analyzing for
          you. Get risk scores for each parameter that you care about.
        </p>
        {/* <img alt={"News"} src={thumbnailGuid2}/> */}
        {/* <img data-src="holder.js/754x354?theme=gray" /> */}
      
        <br />
        <br />
        <h4>Step 3</h4>

        <br />
        <h5>
         Tracking the privacy and cyber security standards, policies and
          track record of apps &amp; services
        </h5>
        <br />
        <p>
          Data is monitored, tracked and reported onto a permanent public
          profile 24/7
        </p>

        <p>
          Get alerts when we find new information about the apps or services you
          care about. You'll also be alerted when an app or service profile
          updates: opportunities of interest, or threats to your privacy,
          reputation and identity.
        </p>
        {/* <img alt={"News"} src={thumbnailGuid2}/> */}
        {/* <img data-src="holder.js/754x354?theme=gray" /> */}

        <h4>Step 4</h4>
        <br />
        <p>
          Users, organizations as well as cyber security, privacy experts and
          regulators can search, view and save the apps they are interested in
          to their dashboard easily.
        </p>

        {/* <img alt={"News"} src={thumbnailGuid2}/> */}
        {/* <img data-src="holder.js/754x354?theme=gray" /> */}
      </div></div>
    </div>
  </Layout>
)

export default AboutPage
